import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-3717c915"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "component-upload-image"
};
const _hoisted_2 = ["src"];
import { reactive, watch } from 'vue';
import { ElMessage } from 'element-plus'; // import { delImgItem } from '@/api/goods'

import { deleteServiceImages } from '@/api/image';
export default {
  __name: 'newImageUpload',
  props: {
    modelValue: [String, Object, Array],
    // 图片数量限制
    limit: {
      type: Number,
      default: 5
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 2
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => ['png', 'jpg', 'jpeg']
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    },
    // 校验图片宽高大小是否符合要求
    isCheckWh: {
      type: Boolean,
      default: true
    },
    whlimit: {
      type: Array,
      default: () => [660, 660]
    },
    moreHeight: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    uploadImgUrl: {
      type: String,
      default: '/rame/gsfms/uploadGoodsImages'
    },
    name: {
      type: String,
      default: 'goodsImages'
    },
    // 删除图片接口
    delImgUrl: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],

  setup(__props, {
    emit: __emit
  }) {
    const emits = __emit;
    const props = __props;
    const data = reactive({
      dialogImageUrl: '',
      dialogVisible: false,
      hideUpload: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      uploadImgUrl: process.env.VUE_APP_BASE_API + props.uploadImgUrl,
      // 上传的图片服务器地址
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
      fileList: [],
      loading: false,
      noneBtnImg: false
    }); // 对象转成指定字符串分隔

    const listToString = (list, separator) => {
      let strs = '';
      separator = separator || ',';

      for (const i in list) {
        strs += list[i].url + separator;
      }

      return strs !== '' ? strs.substr(0, strs.length - 1) : '';
    };

    watch(() => props.modelValue, async val => {
      if (val) {
        // 首先将值转为数组
        // 将当前fileList取出转化为字符串
        if (data.fileList.length > 0) {
          const curStr = await listToString(data.fileList);

          if (val === curStr) {
            return;
          }
        }

        const list = Array.isArray(val) ? val : props.modelValue.split(','); // 然后将数组转为对象数组

        data.fileList = list.map(item => {
          return {
            name: item,
            url: item
          };
        });
      } else {
        data.noneBtnImg = false;
        data.fileList = [];
        return [];
      }
    }, {
      immediate: true,
      deep: true
    });

    const handleImgChange = (file, fileList) => {
      data.noneBtnImg = fileList.length >= props.limit;
    };

    const delImgItemFn = imgArr => {
      console.log('imgArr----', imgArr);
      deleteServiceImages({
        images: [].concat(imgArr)
      }).then(res => {
        if (res.status === 200) {
          ElMessage.success('删除成功');
        }
      });
    }; // 删除图片


    const handleRemove = (file, fileList) => {
      var _file$response;

      console.log('handleRemove的file----', file);
      var imageUrls;
      console.log('删除前data.fileList----', data.fileList);

      if ((_file$response = file.response) !== null && _file$response !== void 0 && _file$response.url) {
        imageUrls = [file.response.url];
      } else {
        imageUrls = [file.url];
      } // const findex = data.fileList.findIndex((f) => f.name.includes(imgName))
      // 删除图片 将剩下的


      const findex = data.fileList.map((f, index) => {
        if (imageUrls[0] === f.url) {
          return index;
        }
      }).filter(item => item !== undefined)[0];

      if (findex > -1) {
        data.fileList.splice(findex, 1);
      } // 接口删除


      delImgItemFn(imageUrls);
      emits('update:modelValue', listToString(data.fileList) ? listToString(data.fileList) : '');
    }; // // 上传成功回调


    const handleUploadSuccess = res => {
      data.fileList.push({
        url: res.url
      });
      emits('update:modelValue', listToString(data.fileList));
    };

    const handleExceed = () => {
      ElMessage.error(`上传文件数量不能超过 ${props.limit} 个!`);
    }; // 上传失败


    const handleUploadError = () => {
      ElMessage({
        type: 'error',
        message: '上传失败'
      });
    }; // 预览


    const handlePictureCardPreview = file => {
      data.dialogImageUrl = file.url;
      data.dialogVisible = true;
    };

    return (_ctx, _cache) => {
      const _component_Plus = _resolveComponent("Plus");

      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_upload = _resolveComponent("el-upload");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_upload, {
        action: data.uploadImgUrl,
        "list-type": "picture-card",
        name: __props.name,
        limit: __props.limit,
        "on-remove": handleRemove,
        "on-error": handleUploadError,
        "on-exceed": handleExceed,
        headers: data.headers,
        multiple: true,
        disabled: __props.disabled,
        "on-change": handleImgChange,
        "file-list": data.fileList,
        "on-success": handleUploadSuccess,
        "on-preview": handlePictureCardPreview
      }, {
        default: _withCtx(() => [_createVNode(_component_el_icon, null, {
          default: _withCtx(() => [_createVNode(_component_Plus)]),
          _: 1
        })]),
        _: 1
      }, 8, ["action", "name", "limit", "headers", "disabled", "file-list"]), _createVNode(_component_el_dialog, {
        modelValue: data.dialogVisible,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => data.dialogVisible = $event),
        title: "预览",
        width: "800",
        "append-to-body": ""
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: data.dialogImageUrl,
          style: {
            "display": "block",
            "max-width": "100%",
            "margin": "0 auto"
          }
        }, null, 8, _hoisted_2)]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }

};